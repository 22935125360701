@import "assets/styles/mixins.scss";

.newFooter {
  padding: rem(13);
  display: flex;
  justify-content: flex-end;
  border-radius: 5px 5px 0 0;
  color: $text-darken-more;
  background: $gray-border;
  .copyright {
    float: none;
    span {
      display: flex;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}
