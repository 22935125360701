// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BADGE */

.ant-page-header-content {
  padding: 0;

  span.subtitle {
    font-size: 0.9rem;
    &.space {
      margin-left: 2.5rem;
    }
    &.space-2 {
      margin-left: 3rem;
    }
  }
  .ant-descriptions {
    &.space {
      margin-left: 2.5rem;
    }
    &.space-2 {
      margin-left: 3.1rem;
    }
  }
  .divider-left {
    &::before {
      width: 2%;
    }
    span.ant-divider-inner-text {
      padding: 0 6px;
    }
  }
}
