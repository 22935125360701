@import './mixins.scss';

b {
  color: rgba(0, 0, 0, 0.85) !important;
}

a {
  outline: none !important;
}

.ant-form-item .ant-select {
  width: 100%;
}

.ant-btn-group {
  display: inline-flex;
}
/*
Adds custom styling to various buttons on the editor
*/
.e-inline-buttons,
.e-list-buttons {
  .rdw-option-active {
    background-color: #e4e4e4 !important;
    box-shadow: none !important;
  }
}

/*
  Adds custom success button
*/
.ant-btn-success {
  color: #fff;
  background-color: $success;
  border-color: $success;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

/*
  Adds custom success text color
*/
.ant-text-success {
  color: $success;
}
/*
Simple mde Editor
*/
.editor-toolbar {
  .table {
    width: 30px !important;
  }
}

.underline {
  text-decoration: underline;
}

.font-weight-500 {
  font-weight: 500 !important;
}
.filters {
  .ant-select {
    width: 100%;
    margin-bottom: 24px;
  }
}

.ant-menu-item-group-title {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #565151 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f9f9f9 !important;
}

#zmmtg-root {
  display: none;
  .footer-button-pause-stop-recording {
    display: none !important;
  }
}
body {
  font-size: 1rem !important;
  &:not(.ant-scrolling-effect) {
    overflow: auto !important;
  }
}
.zoom-live {
  #zmmtg-root {
    display: block;
    z-index: 100;
  }
}

.filter-form {
  .ant-form-item-label > label {
    height: auto !important;
  }
  .ant-col-24.ant-form-item-label {
    padding: 0 !important;
  }
}
