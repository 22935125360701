@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* FORMS */

input::-webkit-input-placeholder {
  color: $gray-darken-more !important;
}
input::-moz-placeholder {
  color: $gray-darken-more !important;
}
input:-moz-placeholder {
  color: $gray-darken-more !important;
}
input:-ms-input-placeholder {
  color: $gray-darken-more !important;
}

// applies color to placeholder text inside select box
.ant-select-selection__placeholder {
  color: $gray-darken-more !important;
}

.form-actions {
  border-top: 1px solid $gray-border;
  padding-top: rem(20);
  margin: rem(20) 0;
}

.form-control {
  font-family: $base__font-family;
  padding: rem(11) rem(16);
  border-color: $gray-border;
  color: $text;
  &.form-control-rounded {
    border-radius: 500px;
  }
  &:focus {
    border-color: $primary;
  }
  &.form-control-sm {
    padding: rem(7) rem(8);
  }
}

.col-form-label {
  padding-top: rem(9);
  padding-bottom: rem(9);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $gray-lighten;
}

.input-group-addon {
  border-color: $gray-border;
  background-color: $gray-lighten;
}

span.input-group-addon {
  outline: none !important;
}

.form-group {
  &.has-danger {
    .form-control {
      border-color: $danger;
    }
  }
  &.has-success {
    .form-control {
      border-color: $success;
    }
  }
  &.has-warning {
    .form-control {
      border-color: $warning;
    }
  }
  &.has-focused {
    .form-control {
      border-color: $primary;
    }
  }
}

.form-input-icon {
  position: relative;
  > i {
    color: $gray-darken-more;
    position: absolute;
    margin: rem(13) rem(2) rem(4) rem(10);
    z-index: 3;
    width: rem(16);
    font-size: rem(16);
    text-align: center;
    left: 0;
  }
  .form-control {
    padding-left: rem(34);
  }
  &.form-input-icon-right {
    > i {
      left: auto;
      right: rem(3);
      margin: rem(13) rem(10) rem(4) rem(2);
    }
    .form-control {
      padding-left: rem(16);
      padding-right: rem(34);
    }
  }
}
